import { MetaData, AreaPage, AreaPageModern } from '@/components'
import {
  fetchAreaInfo,
  fetchMobileApps,
  fetchPartnerPromotions,
  getAreaInfoById,
} from '@/store/slices/stagesSlice/stagesSlice'
import CustomError from '@/helpers/CustomError'
import { initializeStore } from '@/store'
import { NextPage } from 'next'

const NextAreaPage: NextPage<{ id: string; isNewDesign: boolean }> = ({ id, isNewDesign }) => {
  return (
    <>
      <MetaData />
      {isNewDesign ? <AreaPageModern id={id} /> : <AreaPage id={id} />}
    </>
  )
}

NextAreaPage.getInitialProps = async ({ query }) => {
  const areaId: string = query.areaId as string
  const reduxStore = initializeStore()
  const { dispatch, getState } = reduxStore

  await dispatch(fetchAreaInfo({ id: areaId }))
  await dispatch(fetchPartnerPromotions())
  await dispatch(fetchMobileApps())

  const response = getAreaInfoById(getState(), areaId)
  if (response.error) throw new CustomError('', response.error.statusCode)
  const isNewDesign = response?.data && response.data.is_new_payment_design

  return {
    initialReduxState: reduxStore.getState(),
    headerLayout: 'empty',
    id: areaId,
    isNewDesign: Boolean(isNewDesign),
  }
}

export default NextAreaPage
